<template>
  <div class="container">
    <h1>TÉRMINOS Y CONDICIONES</h1>
    <h2>POR FAVOR LEE ESTOS TÉRMINOS DE USO CUIDADOSAMENTE ANTES DE HACER USO DE NUESTROS SERVICIOS. </h2>
    <p>
      Por favor asegúrese de leer estos términos y condiciones (los “Términos”) antes de utilizar los servicios que [WALLEAT.COM S.A DE C.V.] (la “Compañía”) (los “Servicios”). Estos Términos establecen los derechos y obligaciones entre la Compañía y usted, el usuario de los Servicios (el “Usuario”), entendido como “Usuario” a clientes y establecimientos participantes. Al utilizar los Servicios, se entiende que el Usuario está de acuerdo con los derechos y obligaciones establecidos en los Términos.

        1. Aplicación de los Términos
        1.1 El objetivo de estos Términos es establecer las condiciones para la prestación de los Servicios y los derechos y obligaciones entre la Compañía y el Usuario en relación con el uso de los Servicios. Estos Términos deberán aplicar a todas y cada una de las relaciones e interacciones entre el Usuario y la Compañía, en relación con los Servicios. 
        1.2 Si hay cualquier discrepancia entre el contenido de estos Términos y cualquier descripción o información en nuestra aplicación o en la página de internet en relación con los Servicios, el contenido de estos Términos deberá prevalecer. 

        2. Registro para el uso de los Servicios. 
        2.1 Al aceptar estos Términos y proporcionar a la Compañía la información solicitada en los términos requeridos, la persona que requiera utilizar los Servicios (el “Usuario Potencial”) puede solicitar a la Compañía el uso de los Servicios. La Compañía se reserva el derecho de decidir si el Usuario Potencial puede utilizar los Servicios o no. 
        2.2 El Usuario puede utilizar los Servicios una vez que la Compañía ha autorizado el uso de los mismos y esto será conforme los presentes Términos. 

        3. Uso de los Servicios. 
        3.1 Para utilizar los Servicios, el Usuario debe contar con los requerimientos necesarios de sistema, software y equipo tecnológico necesario y compatible para conectarse a internet. El uso efectivo de los Servicios dependerá de tanto del equipo adecuado como de una conexión satisfactoria a internet. La Compañía no será responsable de proporcionar o garantizar el uso del equipo tecnológico adecuado, ni de la conexión a internet, pues es responsabilidad de cada Usuario. 

        3.2 La Compañía no será responsable de la calidad de los bienes adquiridos a través de la plataforma, pues únicamente funciona como intermediario entre compradores y vendedores. 

        3.3 El Usuario podrá realizar compras en línea a través de la página, además, mediante el uso de tecnología NFC y código QR, el usuario puede realizar las compras de manera presencial en los puntos de venta físicos que estén registrados en la plataforma.

        3.4 La plataforma de la Compañía solo acepta pago a través de Codi y con tarjeta de crédito.

        4. Uso de la información de los Usuarios
        4.1 El Usuario es responsable de proporcionar información veraz, correcta y actualizada para poder utilizar los Servicios.
        4.2 La Compañía utilizará los datos de los Usuarios para identificarlos plenamente dentro de la plataforma; optimizar la navegación del Usuario dentro de la misma. Además, los mismos serán usados con fines de facturación y verificación de compras.
        4.3 El Usuario será responsable de cualquier daño o perjuicio ocasionado por el uso inadecuado o errores de su información en relación con los Servicios. La Compañía no será responsable en momento alguno por lo anterior. 

        5. Costos y medio de pago
        5.1 El Usuario será responsable de pagar los costos y tarifas correspondientes por todos los bienes que compre durante el uso de los Servicios, así como cualquier otro cargo adicional que surja a partir del uso de los Servicios (incluyendo impuestos y cargos demorados) o cualquier comisión, por lo cual, el Usuario será responsable de proporcionar a la Compañía la información necesaria para la ejecución de dichos pagos. 
        5.2 La Compañía podrá en cualquier momento cambiar los precios y tarifas de los Servicios a su discreción y en cualquier momento.
        5.3 Para prevenir fraudes, lavado de dinero o cualquier actividad ilícita, la Compañía se reserva el derecho de revisar cualquier pago realizado. La Compañía se reserva el derecho de completar o llevar a cabo cualquier pago conforme lo anterior, en caso de que cuente con elementos suficientes para sospechar que dicha actividad puede ser sospechosa, fraudulenta o relacionada con cualquier actividad ilícita. 



        6. Prohibiciones y restricciones
        6.1 Al utilizar los Servicios, el Usuario no deberá participar o realizar cualquier acto o actividad de las que se describen a continuación o cualquier acto que la Compañía pueda identificar que constituye cualquiera de las siguientes: 
        a) violar cualquier ley, reglamento o acto que resulte ilícito de cualquier forma; 
        b) molestar o interrumpir la prestación de los Servicios de la Compañía o el uso de los Servicios por parte de otro Usuario o tercero; 
        c) realizar actividades contrarias al orden público; 
        d) cualquier incumplimiento a los derechos de propiedad intelectual, contrario a la privacidad, reputación y/o cualquier otro derecho o interés de la Compañía, otros Usuarios o terceros. 
        e) cualquier intento de acceder a la red, plataforma o sistema de los Servicios a través de un acceso no autorizado;
        f) cualquier acto o intento de suspender, invalidar, destruir, hackear o alterar la seguridad de cualquier función o componente relacionado con los Servicios o los contenidos digitales. 
        g) cualquier acto que obstruya la operación y/o la prestación de los Servicios; 
        h) cualquier acto además de los señalados anteriormente, que para la Compañía resulte impropio o inadecuado.
        6.2 Si el usuario participara o se viera involucrado en cualquiera de los actos o actividades señaladas en el numeral anterior, la Compañía podrá tomar la determinación que considere adecuada, sin necesidad de notificarlo previamente al Usuario, a su discreción y sin responsabilidad alguna para la Compañía. 
        6.3 La Compañía podrá monitorear todas las operaciones de los Usuarios en relación con los Servicios.

        7. Suspensión o cancelación de los Servicios. 
        7.1 en caso de que ocurra alguno de los supuestos que a continuación se describen, la Compañía podrá suspender, descontinuar o cancelar todos o cualquiera de los Servicios, sin necesidad de notificación previa al Usuario: 
        a) en caso de revisión de emergencia o mantenimiento a los sistemas a través de los cuales se proporcionan los Servicios; 
        b) en caso de fuerza mayor, desastre natural o caso fortuito, que impida la prestación de los Servicios; 
        c) en caso de que el Usuario se vea involucrado en alguno de los actos o actividades prohibidas o restringidas conforme lo señalado en estos términos; o 
        d) en cualquier otro escenario, en caso de que la Compañía estime necesaria dicho suspensión, descontinuación o cancelación. 
        7.2 La Compañía no será responsable de ningún daño o perjuicio ocasionado al Usuario como resultado de cualquier medida adoptada por la Compañía en relación con lo anteriormente señalado. 
        7.3 Si el Usuario incumple con lo establecido en estos Términos y Condiciones, la Compañía puede negar el acceso a los Servicios y suspender el uso de los Servicios por parte del Usuario, sin notificación previa al Usuario. En este caso, la Compañía no devolverá tarifa o precio alguno pagado por los Servicios por parte del Usuario. 

        8. Retiro del Usuario
        El Usuario podrá retirarse del uso de los Servicios y cancelar su registro conforme el procedimiento establecido por la Compañía para dicho caso. En relación con el retiro y cancelación del registro, cualquier cantidad adeudada a la Compañía por parte del Usuario será inmediatamente exigible y el usuario deberá pagarla a la Compañía. 

        9. Cambios de los Servicios
        9.1 La Compañía podrá modificar los puntos de venta y proveedores que ofrecen a través de sus Servicios o terminar la provisión de los Servicios a su entera discreción.  Si la Compañía termina la provisión de los Servicios, deberá notificar primero al Usuario. 
        9.2 La Compañía no podrá ser responsable de daños y perjuicios causados al Usuario derivados de o relacionados con la modificación, corrección o cancelación de pedidos a través de su plataforma.

        10. Excepciones
        10.1 La Compañía, sus directores, administradores, representantes ni cualquier persona dentro de la organización de la Compañía y/o sus subsidiarias serán responsables de forma alguna de proveer compensación alguna en caso de descontinuación, suspensión, terminación o no disponibilidad de los Servicios o por cualquier daño o perjuicio sufrido por cualquier Usuario en relación con los Servicios. 
        10.2 Aunque por determinación judicial la Compañía fuere responsable de proporcionar algún tipo de compensación económica, dicha compensación no podrá ser mayor al monto pagado por el Usuario a la Compañía por la compra realizada objeto de la reclamación. Adicionalmente, dicha compensación no podrá ser retroactiva en momento alguno y la Compañía no será responsable de pagar cualquier compensación por actos incidentales, indirectos, especiales o cualquier daño futuro e incierto, ni perjuicio alguno. 

        11. Manejo de la información
        La Compañía será responsable del manejo de la información personal de conformidad con el aviso de privacidad y las leyes de protección de datos personales aplicables en México.

        12. Modificaciones a los Términos
        La Compañía podrá modificar los presentes Términos en el momento en el que lo considere necesario. En caso de que se realice cualquier modificación o cambio en los presentes Términos, estos serán publicados en la página de internet de la Compañía. 

        13. Notificaciones
        Para cualquier pregunta, duda o comunicación relacionada con los Servicios o los presentes Términos, el Usuario deberá realizarlo a través de los canales designados por la Compañía. 

        14. Usuario
        El Usuario no podrá por motivo alguno ceder, asignar, transferir, vender, rentar, donar, o de cualquier forma disponer de su cuenta de Usuario o de sus derechos y obligaciones a favor de algún tercero, sin consentimiento previo y por escrito de la Compañía. 

        15. Autonomía de las disposiciones
        La nulidad, invalidez, ilegalidad o cualquier vicio en cualquiera de las disposiciones de los presentes Términos, sólo afectará a dicha disposición y, por lo tanto, no afectará a las demás disposiciones aquí pactadas, las cuales conservarán su fuerza obligatoria.

        16. Jurisdicción y derecho aplicable
        16.1 Los presentes Términos y el acuerdo del uso de los Servicios serán regidos conforme las leyes federales mexicanas. 
        16.2 Cualquier disputa que surja a partir de o en relación con los presentes Términos, el acuerdo respecto a su aplicación o el uso de los Servicios, las Partes acuerdan someterse a la jurisdicción de los tribunales federales mexicanos. 

    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      consent: false,
    };
  },
};
</script>

<style>
.container {
  max-width: 800px;
  margin: 0 auto;
}
h1, h2 {
  margin-top: 20px;
}
</style>